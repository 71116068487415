<template>
  <div>
    <el-form ref="form" :model="form" label-width="80px" :inline="true">
      <el-form-item label="手机号：">
        <el-input v-model="form.phone" placeholder="请输入手机号" clearable></el-input>
      </el-form-item>
      <el-form-item label="代理商：">
        <AgentTree
          :props="props"
          v-model="unitNos"
          placeholder="请选择分账主体"
          style="width: 300px"
          :selectKey='selectKey'
        ></AgentTree>
      </el-form-item>
      <el-form-item label="保险状态：" label-width="150">
        <el-select ref="formStatus" v-model="form.status" placeholder="请选择保险状态" clearable>
          <el-option
            v-for="item in INSURE_STATUS"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-button type="primary" class="ml24" @click="queryBuyInsuranceRecordList">查询</el-button>
    </el-form>
    <el-table
      :data="tableData"
      style="width: 100%">
      <el-table-column
        v-for="item in columns"
        :key="item.label"
        :prop="item.prop"
        :label="item.label"
        width="180">
        <template  slot-scope="{row}">
          <span v-if="item.prop === 'fee' || item.prop === 'money'">{{row[item.prop]}}元</span>
          <span v-else-if="item.prop === 'expiredTime' || item.prop === 'createTime'">{{row[item.prop] | timeToDate}}</span>
          <span v-else-if="item.prop === 'status'">{{$changeObj(INSURE_STATUS)[row[item.prop]]}}</span>
          <span v-else-if="item.prop === 'day'">{{row[item.prop]}}天</span>
          <span v-else>{{row[item.prop]}}</span>
        </template>
      </el-table-column>
    </el-table>
    <div class="df fe">
      <el-pagination
          @current-change="currentChange"
          class="mt20"
          layout="prev, pager, next, total"
          :page-size="form.pageSize"
          :total="total">
      </el-pagination>
    </div>

  </div>
</template>

<script>
import { queryBuyInsuranceRecordList } from "@/api/order";
import {INSURE_STATUS } from "@/js/constant";
import  AgentTree from '@/components/AgentTree';
export default {
  data() {
    return {
      total:1,
      columns:[
        {
          label:'代理商',
          prop:'agentName'
        },
         {
          label:'门店',
          prop:'storeName'
        },
         {
          label:'手机号',
          prop:'phone'
        },
         {
          label:'姓名',
          prop:'name'
        },
         {
          label:'设备通讯板编号',
          prop:'communicationBoardNo'
        },
         {
          label:'申请订单号',
          prop:'orderNo'
        },
          {
          label:'保险费',
          prop:'fee'
        },
          {
          label:'保额',
          prop:'money'
        },
          {
          label:'保险天数',
          prop:'day'
        },
          {
          label:'保险过期时间',
          prop:'expiredTime'
        },
          {
          label:'投保时间',
          prop:'createTime'
        },
          {
          label:'保险状态',
          prop:'status'
        },
      ],
      INSURE_STATUS,
      tableData: [],
      selectKey: '',
      props: {
        value: "unitNo",
        label: "name",
        checkStrictly: true
      },
      unitNos: [],
      form:{
        status:'',
        phone:'',
        beginIndex:0,
        pageSize:20,
        unitNo:''
      },
    }
  },
  beforeDestroy() {
    document.onkeydown = null
  },
  mounted(){
    const _this = this;
    document.onkeydown = function(e) {
      let key = window.event.keyCode;
      if (key == 13) {
        _this.$refs.formStatus.blur()
        _this.queryBuyInsuranceRecordList()
      }
    };
     this.queryBuyInsuranceRecordList();
  },
  methods: {
    currentChange(index){
      this.form.beginIndex = index - 1;
      this.queryBuyInsuranceRecordList();
    },
    async queryBuyInsuranceRecordList(){
      try{
        this.form.unitNo = this.unitNos[this.unitNos.length - 1];

        const params = {...this.form};
        const res = await queryBuyInsuranceRecordList(params);
        this.tableData = res.ldata;
        this.total = res.mdata.total;

      }catch(e){
        console.log(e,'e');
      }
    }
  },
  components: {
    AgentTree,
  }
}
</script>

<style scoped>

</style>
